import * as React from "react"

import { DefaultLayout } from "../../layouts";
import { SEO, Call } from "../../components";

// import { Plans_ddd, Table } from './components'
import { PlansP, Table } from '../../page_components/precios'

import styles from './precios.module.scss';

// markup
const Precios = () => {
    return (
        <DefaultLayout>
            <SEO title="Calendab · Precios" />
            <div className={styles.container}>
                <div className={styles.content}>
                    <PlansP />
                    <Call />
                    <Table />
                </div>
            </div>
        </DefaultLayout>
    )
}
    
export default Precios
    