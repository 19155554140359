import * as React from "react"
import { Link } from 'gatsby'

import { Container, Tooltip } from '../../../components'

import styles from './table.module.scss';

import check from '../../../assets/images/check_.svg';
import cross from '../../../assets/images/cross.png';
import question from '../../../assets/images/question.svg';

// markup
export const Table = () => {

    return (
        <div className={styles.core} id={"table"}>
            <Container>
                <h3 className={styles.title}>
                    Comparar los planes
                </h3>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div>
                            <p className={styles.text}>
                                Descubre las diferencias de cada plan individual y obten información detallada sobre nuestro producto.
                            </p>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.table}>
                            <div className={styles.table_header}>
                                <div className={`columns is-mobile is-multiline ${styles.row} ${styles.row_notop}`}>
                                    <div className={`column is-one-third is-hidden-touch ${styles.cell} ${styles.cell_btn} ${styles.cell_clean}`}></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={styles.cell_title}>Free</div></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={styles.cell_title}>Starter</div></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={styles.cell_title}>Standard</div></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={styles.cell_title}>Pro</div></div>
                                </div>
                            </div>
                            <div className={styles.table_title}>Caracteristicas</div>
                            <div className={styles.table_body}>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell} ${styles.cell_bt} ${styles.cell_bbnm}`}>
                                        <span className={styles.table_name}>Agendas</span>
                                        <Tooltip text="Cantidad de agendas que tiene la cuenta disponibles."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda agendas" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={""}>1</div></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={""}>1</div></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={""}>1</div></div>
                                    <div className={`column ${styles.cell} ${styles.cell_bt}`}><div className={""}>2</div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Categorias</span>
                                        <Tooltip text="Cantidad de categorias disponibles por agenda. Las categorias sirven para 'categorizar' tus citas."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda categorias" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}>2</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>5</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>10</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>20</div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Clientes</span>
                                        <Tooltip text="Cantidad de clientes por agenda."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda clientes" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}>10</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>100</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>300</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>500</div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Citas</span>
                                        <Tooltip text="Cantidad de citas por agenda mensualmente."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda citas" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}>50</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>350</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>1100</div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}>2000</div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Dashboard</span>
                                        <Tooltip text="Panel con información general de tu agenda."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda dashboard " /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Festivos</span>
                                        <Tooltip text="Podras marcar que días cierras la agenda."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda festivos" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Reservas Online</span>
                                        <Tooltip text="Habilita el módulo de reservas online en tu agenda."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda reservas online" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Enlace</span>
                                        <Tooltip text="Enlace por donde tus clientes podran acceder a tu agenda."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda enlace" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Avisos e-mail</span>
                                        <Tooltip text="Habilita el módulo de avisos por e-mail."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda avisos e-mail" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Avisos nueva cita</span>
                                        <Tooltip text="Habilita el modulo de avisos en la creación de nuevas citas."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda avisos nueva cita" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Avisos modificar cita</span>
                                        <Tooltip text="Habilita el modulo de avisos en la modificación de citas."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda avisos modificar cita" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Avisos eliminar cita</span>
                                        <Tooltip text="Habilita el modulo de avisos en la eliminación de citas."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda avisos eliminar cita" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                                <div className={`columns is-mobile is-multiline ${styles.row}`}>
                                    <div className={`column is-one-third-desktop is-full-tablet is-full-mobile ${styles.cell}`}>
                                        <span className={styles.table_name}>Personalizar enlace</span>
                                        <Tooltip text="Podrás personalizar en enlace para compartir con tus clientes."><img src={question} className={styles.icon_small} width="13px" height="13px" alt="ayuda personalizar enlace" /></Tooltip>
                                    </div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={cross} className={styles.icon} width="20px" height="20px" alt="no tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                    <div className={`column ${styles.cell}`}><div className={""}><img src={check} className={styles.icon} width="20px" height="20px" alt="tiene la caracteristica" /></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <p className={styles.text}>¿Sigue sin encajarte ninguno? Ponte en <Link to={"/contacto"} className={styles.link}>contacto</Link> con nosotros y vemos.</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    